@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
}

#root{
  margin: 0;
  padding: 0;
}

body{
  font-family: Arial, Helvetica, sans-serif; 
  overflow-x: hidden;
  background-color: rgb(241 245 249);
}
