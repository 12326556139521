.customloader {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: red;
    animation: spin 1s linear infinite;
    width: 10px;
    height: 10px;
    margin: 20px auto;
    color: red !important;
  }
  
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  