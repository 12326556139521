#root {
  max-width: 1280px; 
  text-align: center;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}


.scrollable-div {
  overflow-y: auto;   /* limit the height to prevent overflow */
}

/* styles for the custom scrollbar */
.scrollable-div::-webkit-scrollbar {
  width: 8px; /* set the width of the scrollbar */
}

.scrollable-div::-webkit-scrollbar-track {
  /* background-color: #f1f1f1; set the background color of the scrollbar track */
}

.scrollable-div::-webkit-scrollbar-thumb {
  background-color: #b8b8b8; /* set the color of the scrollbar thumb */
  border-radius: 4px; /* round the corners of the scrollbar thumb */
}

.postcard-image{
  width: calc(450px - 1rem);
}

  
